@charset "utf-8";
/* CSS Document */
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500&display=swap');
.ss-font-style-1 {
	font-family: 'Anton', sans-serif;
}
.ss-font-style-2 {
	font-family: 'Lexend', sans-serif;
}
.ss-hover:hover {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.ss-bg-white {
	background-color: #fff !important;
}
.ss-shadow {
	box-shadow: -2px 5px 17px 0px rgba(0,0,0,0.69);
	-webkit-box-shadow: -2px 5px 17px 0px rgba(0,0,0,0.69);
	-moz-box-shadow: -2px 5px 17px 0px rgba(0,0,0,0.69);
}
.ss-text-black-half
{
	color: #4e4e4e;
}

.ss-text-black {
	color: #000 !important;
}
.ss-bg-orange {
	background-color: #d67587;
}
.ss-text-orange {
	color: #FF4C00 !important;
}
.ss-bg1 {
	background-color: #82acda;
}
.ss-bg2 {
	background-color:#C19EB9
}
.ss-bg3 {
	background-color: #CD0059;
}

.ss-bg4 {
	background-color:#5fa9ff;
}
#global-nav ul {
	list-style: none;
	padding-left: 15px;
	border-bottom: 1px solid #BEBEBE;
	padding: 5px;
}
#global-nav ul li a {
	font-family: 'Lexend', sans-serif;
	color: #003F7A;
	font-size: 14px;
}
#global-nav ul li ul li a {
	font-family: 'Lexend', sans-serif;
	color: #838383;
	font-size: 13px;
	margin-left: 10px
}
#global-nav ul li ul li a:hover {
	color: #0019AF;
	letter-spacing: 1px;
}
#global-nav ul li {
	margin-top: 2px;
	margin-bottom: 2px;
}
.border-bottom-blue {
	border-bottom: 2px solid #37ADFF;
	width: 100% !important
}
/*for background video */

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}
/* Add some content at the bottom of the video/page */

/* Style the button used to pause/play the video */
#myBtn {
	width: 200px;
	font-size: 18px;
	padding: 10px;
	border: none;
	background: #000;
	color: #fff;
	cursor: pointer;
}
#myBtn:hover {
	background: #ddd;
	color: black;
}
